import messageStyles from './message.module.scss'
import { Helmet } from "react-helmet"
import React, {useEffect, useState} from "react"
export default function Message() {
  const [CurrentTime, setCurrentTime] = useState()
  useEffect(() => {
    function getTime() {
      let date = new Date()
      let time;
      if (date.getMinutes().length === 1) {
        time = date.getHours() + ":0" + date.getMinutes()
      } else {
        time = date.getHours() + ":" + date.getMinutes()
      }
      return time
    }
    setCurrentTime(getTime())
    let interval = setInterval(function () {
      setCurrentTime(getTime())
    }, 1000)
    return (() => {
      clearInterval(interval)
    })
  }, [])
  return (
    <div id={messageStyles.timeline}>
      <Helmet>
        <title>Leave me a msg &lt;3</title>
        <meta name="description" content="Contact me and deliver feedbacks to me! I love feedbacks... so... pretty please..." />
        <meta name="keywords" content="Tim Ming, Dev, Message"></meta>
      </Helmet>
      <div id={messageStyles.device}>
        <div id={messageStyles.deviceShadow}></div>
        <div id={messageStyles.case}>
          <div id={messageStyles.volumeButton}>
            <div id={messageStyles.volumeOut}>
              <div id={messageStyles.volumeIn}></div>
            </div>
          </div>
          <div id={messageStyles.powerButton}>
            <div id={messageStyles.powerOut}>
              <div id={messageStyles.powerIn}></div>
            </div>
          </div>
          <div id={messageStyles.top}>
            <div id={messageStyles.camera}>
              <div id={messageStyles.cameraLens}></div>
              <div id={messageStyles.cameraReflection0}></div>
              <div id={messageStyles.cameraReflection1}></div>
            </div>
            <div id={messageStyles.speaker}></div>
          </div>
          <div id={messageStyles.screen}>
            <div id={messageStyles.screenTop}>
              <div id={messageStyles.screenTopLeft}>
                <p id={messageStyles.screenTime}>{CurrentTime}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="9%" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polygon points="12 2 22 8.5 22 15.5 12 22 2 15.5 2 8.5 12 2"></polygon>
                  <line x1="12" y1="22" x2="12" y2="15.5"></line>
                  <polyline points="22 8.5 12 15.5 2 8.5"></polyline>
                  <polyline points="2 15.5 12 8.5 22 15.5"></polyline>
                  <line x1="12" y1="2" x2="12" y2="8.5"></line>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="9%" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                </svg>
              </div>
              <div id={messageStyles.screenTopRight}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10%" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="0.15rem" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="12" y1="20" x2="12" y2="10"></line>
                  <line x1="18" y1="20" x2="18" y2="4"></line>
                  <line x1="6" y1="20" x2="6" y2="16"></line>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="10%" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="0.15rem" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M5 12.55a11 11 0 0 1 14.08 0"></path>
                  <path d="M1.42 9a16 16 0 0 1 21.16 0"></path>
                  <path d="M8.53 16.11a6 6 0 0 1 6.95 0"></path>
                  <line x1="12" y1="20" x2="12.01" y2="20"></line>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="10%" viewBox="0 0 24 24" fill="white" stroke="white" strokeWidth="0.15rem" strokeLinecap="round" strokeLinejoin="round">
                  <rect x="1" y="6" width="18" height="12" rx="2" ry="2"></rect>
                  <line x1="23" y1="13" x2="23" y2="11"></line>
                </svg>
              </div>
            </div>
            <div>Please wait as I construct this phone :)</div>
          </div>
        </div>
      </div>
    </div>
  )
}